<template>
  <footer class="footer">
    <div class="footer__contacts">
      <div class="footer__social">
        <a
          href="https://t.me/gurmanikzn"
          target="_blank"
          class="footer__social-link"
        >
          <v-icon name="telegram-icon"></v-icon>
        </a>
        <a
          href="https://vk.com/gurmanikzn"
          target="_blank"
          class="footer__social-link"
        >
          <v-icon name="vk-icon"></v-icon>
        </a>
      </div>
      <div class="footer__info">
        <a href="tel:+78432390012" class="footer__phone">
          +7 (843) 239-00-12 -
          <span class="footer__phone-districts">
            <span class="footer__phone-district">Вахитовский район</span>
            <span class="footer__phone-district">Приволжский район</span>
            <span class="footer__phone-district">Советский район</span>
          </span>
        </a>
        <a href="tel:+78432900012" class="footer__phone">
          +7 (843) 290-00-12 -
          <span class="footer__phone-districts">
            <span class="footer__phone-district">Дербышки</span>
          </span>
        </a>
        <a href="tel:+78432966012" class="footer__phone">
          +7 (843) 296-60-12 -
          <span class="footer__phone-districts">
            <span class="footer__phone-district">Кировский район</span>
            <span class="footer__phone-district">Московский район</span>
            <span class="footer__phone-district">Ново-савиновский район</span>
            <span class="footer__phone-district">Авиастроительный район</span>
          </span>
        </a>
        <h3 class="footer__info-title">
          {{
            `${SITE_SETTINGS?.curr_day?.day_start} - ${SITE_SETTINGS?.curr_day?.day_end}`
          }}
        </h3>
        <!-- <h3 class="footer__info-title">г. Казань</h3> -->
      </div>
    </div>

    <div class="footer__metrics">
      <slot></slot>
      <div class="footer__yandex"></div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "v-footer",
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters(["SITE_SETTINGS"]),
  },
};
</script>
<style lang="scss">
.footer {
  padding: 0px 20px 20px 20px;
  padding-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 30px !important;
  &__title {
    @include h2;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
  &__list {
    margin-bottom: 18px;
  }
  &__contacts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  &__social {
    width: 84px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    &-link {
      svg {
        min-width: 32px;
        min-height: 32px;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    &-title {
      @include h3;
      margin-top: 5px;
    }
  }
  &__phone {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    &-districts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &-district {
      margin-left: 4px;
      font-size: 10px;
    }
  }
  &__metrics {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }
  &__business {
    width: 100%;
    text-align: center;
    color: $accent;
    margin-bottom: 10px;
  }
}

@media (max-width: 410px) {
  .footer {
    &__contacts {
      flex-direction: column;
    }
    &__info {
      margin-top: 12px;
      text-align: center;
    }
    &__phone {
    }
  }
}
</style>
